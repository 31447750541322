<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <span>群标签:</span>
            <el-input
              v-model="searchOption.key"
              placeholder="请输入群标签名称"
            ></el-input>
          </div>
        </el-space>
        <div>
          <el-button type="primary" @click="searchAction">搜索</el-button>
          <el-button @click="resetAction">重置</el-button>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <div class="list-title">
        <div>
          群标签列表（共 <span>{{ pageObj.TotalRow || 0 }}</span
          >个）
        </div>
        <div>
          <el-button type="primary" @click="autoCreateAction">自动打群标签</el-button>
          <el-button type="primary" @click="createAction">添加标签</el-button>
        </div>
      </div>

      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column
          type="index"
          width="50"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column prop="tagname" label="标签名称" width="160">
          <template #default="scope">
            <div class="tag-wrap">
              <!-- <p class="tag-cell">{{ scope.row.tagname }}</p> -->
              <el-tag type="success">{{ scope.row.TagName }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="标签群数">
          <template #default="scope">
            <div class="action-wrap">
              <router-link :to="{path:'/groupDetail',query:{TagID:scope.row.ID,TagName:scope.row.TagName}}">{{
                scope.row.GroupsCount||0
              }}</router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="CreateTime" label="添加时间"></el-table-column>
        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button type="primary" @click="checkDetail(scope.row)">编辑</el-button>
            <el-button type="primary" @click="checkOrder(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
    </div>
    <AddTag ref="addTagDialog" @onSureAction="confirmAction" />
    <EditTag ref="editTagDialog" @onSureAction="editconfirmAction" />
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import AddTag from "@/components/GroupManager/AddTag.vue";
import EditTag from "@/components/GroupManager/EditTag.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {GetTagList,DisableTag}from "@/helper/tag"
export default {
  components: {
    TableFoot,
    AddTag,
    EditTag,
  },
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const dialogVisible = ref(false);
    const addTagDialog = ref(null);
    const editTagDialog = ref(null);
    const groupList = ref([]);
    const titleMap = reactive({
      addEquipment: "新增标签",
      editEquipment: "修改标签",
    });
    const groupName = ref("");
    const dialogStatus = ref("");
    onMounted(() => {
      searchAction();
    });

    //删除
    function checkOrder(item) {
      ElMessageBox.confirm(`确认删除该群标签？`, "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          DisableTag({TagID:item.ID}).then(res=>{
            searchAction();
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
          })
        }).catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }

    //自动打标签
    function autoCreateAction(){
      router.push({name: "AutoGroupTag"})
    }

    //创建标签
    function createAction() {
      addTagDialog.value.initCop();
    }

    //编辑确定
    function editconfirmAction() {
      searchAction();
    }

    //编辑标签
    function checkDetail(item) {
      editTagDialog.value.initCop(item);
    }

    function confirmAction() {
      searchAction();
    }

    /**********************搜索***************************/
    const searchOption = reactive({
      key: "",
    });

    //执行搜索
    function searchAction() {
      let param = {
        PageNum:pageObj.PageIndex,
        PageSize:pageObj.PageSize,
        TagName:searchOption.key||""
      };
      GetTagList(param).then(res=>{
        pageObj.TotalRow = res.count,
        tableData.value = res.list
      })
    }

    //重置条件
    function resetAction() {
      searchOption.key = "";
      searchAction();
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    
    return {
      tableData,
      searchOption,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      createAction,
      dialogStatus,
      titleMap,
      dialogVisible,
      confirmAction,
      checkDetail,
      groupName,
      groupList,
      checkOrder,
      addTagDialog,
      editTagDialog,
      editconfirmAction,
      autoCreateAction
    };
  },
};
</script>

<style lang="scss" scoped>
.group-box {
  display: flex;
  align-items: center;
  justify-content: center;
  :deep().el-input {
    width: 220px;
    margin: 0 20px;
  }
}
.group-box2 {
  display: flex;
  align-items: center;
  margin-left: 80px;
  //   justify-content: center;
  margin-top: 20px;
}
.tag-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .tag-cell {
    padding: 0 5px;
    height: 20px;
    font-size: 12px;
    background-color: #97d1f4;
    color: #787c7e;
    border: 1px solid #ccc;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.list-title div span {
  color: $color-common;
}
</style>