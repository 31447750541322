<template>
  <div>
    <el-dialog title="编辑群标签" width="600px" v-model="dialogVisible">
      <div class="group-box">
        <div>群标签名称:</div>
        <el-input
          v-model="groupName"
          placeholder="请输入内容"
          :maxlength="10"
        ></el-input>
        <span>最长10个字 </span>
      </div>
      <div class="group-box2">
        <div style="margin-right: 25px">选择群:</div>
        <el-button type="primary" style="margin: 0 20px" @click="groupAction"
          >选择群</el-button
        >
        <span
          >已选择(<span style="color: #bfb18a">{{ groupList.length || 0 }}</span
          >)个群</span
        >
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
    <GroupSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import GroupSelectCop from "@/components/GroupSelectCop";
import { ElMessageBox, ElMessage } from "element-plus";
import {UpdateTag,UpdateGroupIDsToTag} from "@/helper/tag"
export default {
  components: {
    GroupSelectCop,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const groupList = ref([]);
    const groupSelectDialog = ref(null);
    const groupName=ref("")
    const tagid = ref(0)
    function initCop(item) {
      console.log(item)
      tagid.value = item.ID;
      groupName.value = item.TagName||'';
      groupList.value = item.GroupIDs||[];
      dialogVisible.value = true;
    }
    
    //选择群点击
    function groupAction() {
      groupSelectDialog.value.initCop(0,groupList.value);
    }

    //确认群
    function chooseGroupAction(arr) {
      console.log(arr);
      groupList.value = arr.map(item=>item.GroupID);
    }

    /****************点击事件********************/
    //检查是否符合创建规则
    function checkFlag() {
      if(!groupName.value||groupName.value.length<=0){
        ElMessage({
          type: "error",
          message: "须填写标签名!",
        });
        return false
      }
      return true
    }

    function confirmAction(){
      if(checkFlag()){
         
        let data ={
          TagID:tagid.value,
          GroupIDs:groupList.value,
          TagName:groupName.value,
        }
        UpdateTag(data).then(res=>{
          if( groupList.value.length>0){
            let param = {
              GroupIDs:groupList.value,
              TagID:tagid.value,
              Type:1
            }
            UpdateGroupIDsToTag(param).then(res=>{
              ElMessage({
                type: "success",
                message: "添加成功!",
              });
              dialogVisible.value = false;
              context.emit("onSureAction")
            })
          } else {
            ElMessage({
              type: "success",
              message: "添加成功!",
            });
            dialogVisible.value = false;
            context.emit("onSureAction")
          }
          
        })
      }
    }

    return {
      dialogVisible,
      groupSelectDialog,
      groupName,
      groupList,
      initCop,
      chooseGroupAction,
      groupAction,
      confirmAction,
      tagid
    };
  },
};
</script>

<style lang="scss" scoped>
.group-box {
  display: flex;
  align-items: center;
  justify-content: center;
  :deep().el-input {
    width: 220px;
    margin: 0 20px;
  }
}
.group-box2 {
  display: flex;
  align-items: center;
  margin-left: 80px;
  //   justify-content: center;
  margin-top: 20px;
}
</style>